<template>
    <div class="container">
        <div class="unlicensed-head-style">
            <div v-show="showtip" class="unlicensed-prompt-style">
                <span>无牌车{{carId}}入场时间：{{getTime}} 请通行</span>
            </div>
            <div class="unlicensed-header-style-bg">
                <div class="unlicensed-header-style-text">
                    <p class="p-text1">车场名称：{{parkingName}}</p>
                    <div style="display: flex;justify-content: space-between;width: 100%;" class="div-info">
                        <p class="p-text2">温馨提示</p>
                        <p class="p-text2" style="color: #82c276;font-size: 14px;" @click="handleClickETC">开通ETC缴费</p>
                    </div>
                    <span class="p-text2">无牌车入场后，出场时须同一个微信号扫码</span>
                    <span class="p-text2">无法入场时，可以通过"异常呼叫"联系车场管理人员，进行人工处理</span>
                </div>
                <div @click="parkingFee" class="unlicensed-button-style1">
                    <img src="../assets/img/img1.png">
                    <span>无牌入场</span>
                </div>
                <div @click="showBtn" class="unlicensed-button-style2">
                    <img src="../assets/img/img2.png">
                    <span>异常呼叫</span>
                </div>
            </div>
        </div>
        <div v-if="isShowConfirm" class="my-confirm" @click.stop="clickFun()">
            <div class="confirm-content-wrap" @click.stop>
                <h3 class="my-confirm-title">温馨提示</h3>
                <p class="my-confirm-content">呼叫管理员，是否确认呼叫？</p>
                <div class="my-operation">
                    <div class="confirm-btn" @click="clickClose()">
                        <p class="my-btn-text" @click="clickJump">确定</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay-box" v-show="showETC">
            <div class="popup-box">
                <KeyInput ref="childKey" @handleConfirmBtnFn="handleConfirmBtnFn" :plateNumber="plateNumber"/>
                <div class="popup-btn">
                    <van-button type="primary" @click="handleDefine">确 定</van-button>
                    <van-button color="#9e9e9e" type="default" @click="handleClose">取 消</van-button>
                </div>
                <div class="header-style-text">
                    <span class="p-text2">温馨提示：</span>
                    <p class="p-text2">授权车场对安装ETC的车辆在出场时实现自动扣费；<span style="color: red;">请注意，如果您的车辆没有ETC的话出场会变慢，请按照实际情况操作。</span>
                    </p>
                </div>
            </div>
        </div>

        <van-popup v-model="showPop" class="ad-popup" :close-on-click-overlay="false">
            <div style="width: 100%;">
                <img :src="adImg" @click="handleClick">
            </div>
            <van-icon style="margin-top: 20px" size="40px" name="clear" @click="showPop = false"/>
        </van-popup>
    </div>
</template>

<script>
    import {Button, Icon, Popup} from 'vant';
    import {addUnlicensedCar, etcOpen, getAdvertisement, getInfo, getNotice, queryByRoad} from "../api/interface";
    import Toast from "vant/lib/toast";
    import wx from 'weixin-js-sdk';
    import KeyInput from '../components/KeyInput';

    export default {
        name: "admission",
        components: {
            KeyInput,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [Icon.name]: Icon,
        },
        data() {
            return {
                getTime: "",
                parkingName: "",
                roadId: "",
                path: "",
                userId: "",
                showbtn: false,
                isShowConfirm: false, // 用于控制整个窗口的显示/隐藏
                showtip: false,
                carId: "",
                payType: "",
                adImg: "",
                targeType: "",
                target: "",
                showETC: false,
                showPop: false,
                plateNumber: ""
            }
        },
        created() {
            let _this = this;
            // this.getInfoWeapp();
            let name = _this.$route.query.parkingName.split("'");
            _this.parkingName = name[1];
            _this.roadId = _this.$route.query.roadId;
            _this.userId = sessionStorage.getItem('userId');
            _this.path = "/abnormalCall?userID=" + _this.userId + "&roomID=" + _this.roadId;
        },
        mounted() {
            this.payType = sessionStorage.getItem('payType');
            this.getAreaQueryByRoad();
            // this.advertisement();
        },
        methods: {
            getAreaQueryByRoad() {
                queryByRoad(this.$route.query.roadId).then((res) => {
                    if (res.data.code == 'SUCCESS') {
                        if (res.data.data.chargeType == 2) {
                            this.$router.push({
                                path: '/admissionPayment', query: {
                                    roadId: this.roadId,
                                    parkingName: this.parkingName
                                }
                            });
                        }
                    }
                })
            },

            advertisement() {
                let param = {
                    roadId: this.$route.query.roadId,
                    location: 9
                }
                getAdvertisement(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        if (res.data.data.materias[0].url) {
                            this.showPop = true;
                        }
                        this.adImg = res.data.data.materias[0].url;
                        this.targeType = res.data.data.targeType;
                        this.target = res.data.data.target;
                    }
                })
            },
            handleClick() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            showBtn() {
                this.isShowConfirm = true
            },
            clickFun() {
                this.isShowConfirm = false
            },
            clickClose() {
                var btn = document.getElementById("launch-btn");
                // if (btn.addEventListener) {
                //     getNotice(this.$route.query.roadId).then((res) => {
                //         console.log("通知成功")
                //     })
                // }
                this.isShowConfirm = false
            },
            clickJump() {
                this.$router.push(this.path)
            },
            getInfoWeapp() {
                getInfo(window.location.href.split('#')[0]).then((res) => {
                    wx.config({
                        debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: "wxa9aa498cc6b6b514",         // 必填，公众号的唯一标识，填自己的！
                        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                        nonceStr: res.data.data.nonceStr,   // 必填，生成签名的随机串
                        signature: res.data.data.signature, // 必填，签名，见附录1
                        jsApiList: [
                            'wx-open-launch-weapp',
                        ],
                        openTagList: ["wx-open-launch-weapp"] // 跳转小程序时必填
                    })
                })
                wx.ready(function (res) {
                    // alert(res.errMsg)
                })
                wx.error(function (res) {
                    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                    alert(res)
                });
            },
            getCurrentTime() {
                //获取当前时间并打印
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth() + 1;
                let dd = new Date().getDate();
                let hh = new Date().getHours();
                let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
                this.getTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
            },
            parkingFee() {
                addUnlicensedCar(this.$route.query.roadId).then((res) => {
                    if (res.data.code === 'SUCCESS') {
                        this.carId = res.data.data;
                        this.getCurrentTime();
                        this.showtip = true;
                        Toast(res.data.message)
                    } else {
                        this.showtip = false;
                    }
                })
            },
            handleClickETC() {
                this.showETC = true;
            },
            handleConfirmBtnFn(val) {
                this.plateNumber = val.replace(/(^\s+)|(\s+$)/g, "");
                this.plateNumber = this.plateNumber.replace(/\s/g, "");
            },
            handleClose() {
                this.$refs.childKey.handleConfirmBtnFn();
                this.showETC = false;
                this.plateNumber = "";
            },
            handleDefine() {
                this.$refs.childKey.handleConfirmBtnFn();
                let param = {
                    licensePlate: this.plateNumber,
                    open: true
                }
                etcOpen(param).then(res => {
                    this.showETC = false;
                    this.plateNumber = "";
                    if (res.data.code == 'SUCCESS') {
                        Toast.success('开通成功！');
                    }
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>

<style lang="less" scoped>
    .container {
        width: 100vw;
        height: 100vh;
        background: #fff;
        display: flex;
    }

    .unlicensed-head-style {
        background: url("../assets/img/img1.jpg");
        /* 当使用该设置时（方法一），当可视高度不变时，可视宽度变化时，背景图片的高度也会自适应变化 */
        background-size: contain;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        background-attachment: fixed;
        width: 100%;
        position: relative;
    }

    .unlicensed-header-style-bg {
        background-color: #fbfbfb;
        border-radius: 10px;
        position: absolute;
        top: 15%;
        margin: 0 15px;
        padding: 15px 20px;
        /*box-shadow: #747c7c5e 10px 10px 30px 5px ;*/
    }

    .unlicensed-header-style-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #000;
    }

    .p-text1 {
        display: flex;
        font-size: 16px;
    }

    .p-text2 {
        font-size: 12px;
        color: #c6c7c7;
        margin: 5px 0;
        text-align: left;
    }

    .unlicensed-header-style-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .unlicensed-header-style-img img {
        width: 100px;
        height: 60px;
    }

    .unlicensed-prompt-style {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        background-color: rgb(255 255 0 / 35%);
    }

    .unlicensed-prompt-style span {
        font-size: 12px;
        color: #F44336
    }

    .unlicensed-button-style1 {
        display: flex;
        justify-content: center;
        background: linear-gradient(-30deg, #DC5040, #EC7955);
        border-radius: 5px;
        color: #FFFFFF;
        font-size: 14px;
        padding: 10px;
        margin: 40px 0 20px 0;
    }

    .unlicensed-button-style1 img {
        width: 25px;
        height: 25px;
        margin: 0 10px;
    }

    .unlicensed-button-style2 {
        display: flex;
        justify-content: center;
        border: 1px solid #9a9f9d;
        border-radius: 5px;
        color: #000000;
        font-size: 14px;
        padding: 10px;
        margin: 15px 0;
    }

    .unlicensed-button-style2 img {
        width: 15px;
        height: 15px;
        margin: 0 10px;
    }

    .overlay-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
    }

    .popup-box {
        position: fixed;
        top: 15%;
        left: 0;
        right: 0;
        font-size: 14px;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin: 0 15px 10px 15px;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
    }

    .header-style-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #8a8a8a;
        margin: 0 33px;
        line-height: 25px;
    }

    .p-text2 {
        font-size: 12px;
        text-align: left;
    }

    .license-plate {
        padding: unset;
    }

    .license-plate /deep/ .cph-wrap-shadow {
        margin: 2px auto;
    }

    /deep/ .cph-box {
        background-color: unset;
        z-index: 0;
    }

    .popup-box /deep/ .keyboard-wrap {
        z-index: 1;
    }

    .popup-btn {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
    }

    .popup-btn .van-button {
        width: 100px;
        border-radius: 5px;
        border: none;
        font-weight: 500;
        margin: 20px 10px;
    }

    .my-confirm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 998;
        /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 进入和出去的动画 */
    .confirm-fade-enter-active {
        animation: opacity 0.3s;
    }

    .confirm-fade-enter-active .confirm-content-wrap {
        animation: scale 0.3s;
    }

    .confirm-fade-leave-active {
        animation: outOpacity 0.2s;
    }

    /* 包裹层容器样式 */
    .confirm-content-wrap {
        position: absolute;
        top: 28%;
        left: 0;
        right: 0;
        width: 280px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 5px;
        z-index: 999;
        user-select: none;
    }

    /* 顶部标题部分 */
    .my-confirm-title {
        padding-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #333;
    }

    /* 中间内容部分 */
    .my-confirm-content {
        padding: 20px 15px 15px;
        margin-bottom: 32px;
        text-align: center;
        font-size: 16px;
        color: #666;
        line-height: 1.5;
    }

    /* 底部按钮样式 */
    .my-operation {
        display: flex;
        border-top: 1px solid #eee;
    }

    .my-operation .my-cancel-btn, .confirm-btn {
        flex: 1;
    }

    .my-operation .confirm-btn {
        color: #ffb000;
    }

    .my-operation .my-btn-text {
        text-align: center;
        font-size: 16px;
        margin: 8px 0;
        padding: 6px 0;
    }

    /* 其他修饰样式 */
    .my-border-right {
        border-right: 1px solid #eee;
    }

    /* 进来的动画 */
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes scale {
        0% {
            transform: scale(0);
        }
        60% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }

    /* 出去的动画 */
    @keyframes outOpacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    /deep/ .van-overlay {
        background-color: rgba(0, 0, 0, .2);
    }

    /deep/ .van-popup--center {
        width: 100%;
    }

    .ad-popup {
        background: none !important;
    }

    .ad-popup img {
        width: 70%;
    }
</style>
